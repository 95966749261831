import api from '@/api'
import status from '@/utils/status'
import format from '@/utils/format'
import options from '@/utils/options'

const state = {
	info: {
		client: {},
		code: '',
		detail: {},
		fileList: [],
		summary: {},
		title: '',
		upperProject: {}
	},
	summary: [],
	detail: [],
	client: [],
	form: {
		client : {
			id: "",
			name: "",
			parentClient: {
				id : "",
				name : ""
			},
			bizNum: "",
			ceoName: "",
			contact: {
				phone : "",
				fax : ""
			},
			address: "",
			addressDetail: "",
			memo: "",
			managers: []
		},
		project : {
			code : "",
			requestDate: "",
			completeDate: "",
			dept: {
				id : "",
				name : ""
			},
			user: {
				id : "",
				name : ""
			},
			type: {
				value : "",
				text : ""
			},
			useFor: {
				value : "",
				text : ""
			},
			analysisDept: {
				id : "",
				name : ""
			},
			amount: "",
			sampleName: "",
			sampleDeliveryWay: "",
			sampleCount: "",
			description: "",
			address : "",
			addressDetail :"",
			title : "",
			storageMaterial: "",
			parentProject: { 
				id: '', 
				text: ''
			},
			disposal: {
				name: '',
				contact: '',
				address: ''
			},
			backfill: "",
		}
	},
	files: [],
	specimenFileList : [],
	autoOptions: {
		sampleName: [],
	},
	autoCode: ''
}

const getters = {
	info: state => {
		return state.info
	},
	files: state => state.files,
	summary: state => {
		let list = []
		let keys = Object.keys(state.summary);
		keys.forEach(key => {
			list.push({
				key: key,
				name: status.project_detail.summary[key],
				value: format.dataValue(key, state.summary),
				color: status.color[state.summary[key]] ?? 'black'
			})
		})
		list.push({
			key: 'upperClient',
			name: '상위 업무 번호',
			value: state.info.parentProject?.code ? state.info.parentProject.code : '',
			type: 'link'
		})
		return list;
	},
	detail: state => {
		let list = []
		//20240813 토양일 때에는 되메움토 발생지 필드 추가 필요. 타입값을 몰라 부서명에서 토양 타입 여부 판별
		let fieldList = [];
		if(state.info?.detail?.projectAnalysisDept?.indexOf('토양') >= 0) {
			fieldList = status.project_detail.soil_detail
		} else if(state.info?.detail?.projectAnalysisDept?.indexOf('폐기물') >= 0) {
			fieldList = status.project_detail.disposal_detail;
		} else {
			fieldList = status.project_detail.detail
		}
		fieldList.forEach(item => {
			list.push({
				key: item.key,
				name: item.name,
				value: format.dataValue(item.key, state.detail),
				type: item.type
			})
		})
		list.unshift({
			key: 'parentProject',
			name: '상위 업무 번호',
			value: state.info.parentProject?.id ? state.info.parentProject.id : ''
		})
		return list;
	},
	client: state => {
		let list = []
		status.project_detail.client.forEach(item => {
			list.push({
				key: item.key,
				name: item.name,
				value: format.dataValue(item.key, state.client),
				type: item.type
			})
		})
		return list;
	},
	form: state => state.form,
	autoOptions: state => state.autoOptions,
	autoCode: state => state.autoCode
}

const mutations = {
	setInfo: (state, data) => {
		state.info = {...state.info , ...data}
		state.summary = {...data.summary}
		state.detail = {...data.detail}
		state.client = {...data.client}
	},
	setFile: (state, data) => {
		if(data)
			state.files = [...state.files, ...data]
	},
	resetForm: (state) => {
		state.form = {...state.form}
		const cKeys = Object.keys(state.form.client)
		let client = {};
		cKeys.forEach(key => {
			if(Array.isArray(state.form.client[key])) {
				client[key] = []
			} else if(typeof state.form.client[key] == 'object') {
				client[key] = {id: '', name: ''}
			} else {
				client[key] = ''
			}
		})
		const pKeys = Object.keys(state.form.project)
		let project = {};
		pKeys.forEach(key => {
			project[key] = ''
			if(typeof state.form.project[key] === 'object') {
				project[key] = {id: '', name: '', text: ''}
			}
			if(key === 'backfill') {
				project[key] = ''
			}
		})
		state.form = {client: client, project: project}
	},
	setForm: (state, data) => {
		let detail = {...state.form}
		if(data) {
			/*TODO: 이후 데이터 이름명 동일하게 맞출 필요성 존재*/
			detail.client.name = data.client.clientName;
			detail.client.id = data.client.clientId;
			detail.client.contact.phone = format.phone(data.client.clientPhone);
			detail.client.contact.fax = data.client.clientFax;
			detail.client.memo = data.client.clientMemo ?? '';
			detail.client.bizNum = data.client.clientBizNum ?? '';
			detail.client.ceoName = data.client.clientCeoName;
			detail.client.address = data.client.clientAddress;
			detail.client.managers = data.client.managers;
			detail.client.manager = data.client.managers.map(el => el.id);

			detail.project.code = data.code;
			detail.project.title = data.title;
			detail.project.parentProject.text = data.parentProject?.code;
			detail.project.parentProject.id = data.parentProject?.id;
			detail.project.type.text = data.detail.projectType;
			detail.project.type.value = data.detail.projectType;
			detail.project.completeDate = data.detail.projectDueDate;
			detail.project.requestDate = data.detail.projectRequestDate;
			detail.project.useFor.text = data.detail.projectUseFor;
			detail.project.useFor.value = data.detail.projectUseFor;
			detail.project.user.name = data.detail.projectUser;
			detail.project.dept.name = data.detail.projectDept ?? '';
			detail.project.analysisDept.name = data.detail.projectAnalysisDept;
			detail.project.sampleDeliveryWay = data.detail.projectSampleDeliveryWay;
			detail.project.description = data.detail.projectDescription
			detail.project.address = data.detail.projectAddress;
			detail.project.backfill = data.detail.projectBackfill;
			state.form = detail;
			detail.project.disposal = {};
			detail.project.disposal.name = data.detail.projectDisposalName;
			detail.project.disposal.phone = data.detail.projectDisposalPhone;
			detail.project.disposal.address = data.detail.projectDisposalAddress;
			detail.project.amount = data.detail.projectAmount;
		}
	},
	setAutoCompleteOptions: (state, obj) => {
		state.autoOptions[obj.key] = [...obj.list.map(el => {
			return {
				text: el,
				value: el
			}
		})]
	},
	setAutoCode: (state, data) => {
		state.autoCode = data;
	}
}

const actions = {
	loadInfo: async ({state, commit}, id) => {
		const res = await api.project.getInfo(id)
		commit('setInfo', res)
		commit('resetForm')
		commit('setForm', res)
	},
	add: async ({state, commit}, body) => {
		const res = await api.project.add(body)
		return res;
	},
	edit: async ({state, commit}, payload) => {
		const {id, body} = payload
		const res = await api.project.update(id, body)
		return res;
	},
	updateStatus: async ({state, dispatch}, payload) => {
		const {id, body} = payload
		const res = await api.project.updateStatus(id, body)
		dispatch('loadInfo', id)
		return res;
	},
	loadFile: async ({state, commit}, id) => {
		const res = await api.project.getFile(id);
		commit('setFile', res)
	},
	addFile: async({state, commit}, {id, body}) => {
		const res = await api.project.addFile(id, body)
		return res
	},
	deleteFile: async({state, commit}, id) => {
		const res = await api.project.deleteFile(id)
		return res;
	},
	//업무 생성 / 수정시 리스트 자동완성
	loadAutoComplete: async ({state, commit}, {key, projectId}) => {
		const res = await api.project.loadAutoComplete(key, projectId) 
		commit('setAutoCompleteOptions', {list: res.list, key: key})
	},
	//업무 생성 / 수정 시 자동 문서번호 생성
	loadAutoCode: async ({state, commit}, type) => {
		const res = await api.project.loadAutoNum(type)
		commit('setAutoCode', res?.code)
		return res;
	}
}

export default { namespaced: true, state, getters, mutations, actions }