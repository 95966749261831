import api from '@/api'
import tableHeaders from '@/utils/tableHeaders'
import status from '@/utils/status'
import format from '@/utils/format'

const state = {
	info: {
		childClient: [],
		info: [],
		manager: [],
		name: '',
		parentClient: [],
		projectHistory: [],
	},
	upper: {
		headers: [],
		list: [],
		count: 1,
	},
	history: {
		headers: [],
		list: [],
		count: 1,
	},
	form: {
		name: "",
		parent: {
			id: '',
			name: ''
		},
		bizNum: "",
		ceoName: "",
		contact: {
			phone: "",
			fax: ""
		},
		address: "",
		addressDetail: "",
		memo: "",
		manager: [],
		accountManager: [],
		fact: {
			name: '',
			count: '',
			product: ''
		}
	},
	list: [],
	searchClient: [],
	searchUpperClient: [],
	childClient: [],
	managers: [],
	accountManagers: []
}

const getters = {
	info: state => {
		state.info.info = state.info.info.map(item => {
			if(item.fieldKey.toLowerCase().indexOf('phone') >= 0 || item.fieldKey.toLowerCase().indexOf('fax') >= 0) {
				item.value = format.phone(item.value)
			}
			return item;
		})
		if(state.info.parentClient.length > 0) {
			state.info.info.push({
				name: '상위 고객사 이름',
				fieldKey: 'parent_client_name',
				value: state.info.parentClient.filter(el => el.fieldKey === 'parent_client_name')[0]?.value,
				id: state.info.parentClient.filter(el => el.fieldKey === 'parent_client_id')[0]?.value
			})
		}
		state.info.manager =  state.info.manager.map(item => {
			if(item.fieldKey.toLowerCase().indexOf('phone') >= 0 || item.fieldKey.toLowerCase().indexOf('fax') >= 0) {
				item.value = format.phone(item.value)
			}
			return item;
		})
		return state.info
	},
	upper: state => {
		state.upper.headers = tableHeaders.clientTableHeader.map(header => {
			header.key = header.headerKey
			header.label = header.text
			header.formatter = (value, key, item) => {
				//date format
				if(key.indexOf('Date') >= 0) {
					value = format.date({date: value, onlyDate: true})
				}
				return value;
			}
			return header;
		})
		return state.upper
	},
	history: state => {
		state.history.headers = tableHeaders.workHistoryTableHeader.map(header => {
			header.key = header.headerKey
			header.label = header.text
			header.formatter = (value, key, item) => {
				//date format
				if(key.indexOf('Date') >= 0) {
					value = format.date({date: value, onlyDate: true})
				}
				//status format
				else if(key == 'status') {
					value = { text: status.text[value], color: status.color[value]}
				}
				//정산
				else if(key === 'amount') {
					value = `${format.number(value)}원`
				} 
				return value;
			}
			return header
		})
		return state.history;
	},
	form: state => state.form,
	searchClient: state => {
		return state.searchClient.map(option => {
			option.text = `${option.name}(${option.code})`
			option.value = option.id
			return option
		})
	},
	searchUpperClient: state => {
		return state.searchUpperClient.map(option => {
			option.text = `${option.name}(${option.code})`
			option.value = option.id
			return option
		})
	},
	childClient: state => state.childClient,
	managers: state => state.managers,
	accountManagers: state => state.accountManagers
}

const mutations = {
	setInfo: (state, data) => {
		state.info = {...state.info , ...data}
	},
	setHistory: (state, data) => {
		state.history.list = [...data.table.list]
		state.history.count = data.table.count
	},
	setUpper: (state, data) => {
		state.upper.list = [...data]
		state.upper.count = data.length
	},
	setForm: (state, data) => {
		state.form = {...state.form};

		const setValue = (info, key) => {
			return info.filter(item => item.fieldKey === key)[0]?.value;
		}

		if(data.name) {
			//고객사 정보
			state.form.id = data.id;
			state.form.name = data.name;
			state.form.parent = data.parentClient[0] ? data.parentClient[0]: {id: '', name: ''};
			state.form.bizNum = setValue(data.info, 'bizNum');
			state.form.ceoName = setValue(data.info, 'ceoName');;
			state.form.contact.phone = format.phone(setValue(data.info, 'phone'))
			state.form.contact.fax = format.phone(setValue(data.info, 'fax'))
			state.form.address = setValue(data.info, 'address');
			state.form.addressDetail = setValue(data.info, 'address_detail');
			//상위고객
			state.form.parent.id = data.parentClient.filter(el => el.fieldKey === 'parent_client_id')[0]?.value;
			state.form.parent.name = data.parentClient.filter(el => el.fieldKey === 'parent_client_name')[0]?.value;
			//담당자 정보
			state.form.manager.name = setValue(data.manager, 'manager_name');
			state.form.manager.phone = format.phone(setValue(data.info, 'manager_phone'))
			state.form.manager.email = setValue(data.manager, 'manager_email');
			state.form.manager.contact = format.phone(setValue(data.info, 'manager_contact'))
			//회계담당자 정보
			state.form.accountManager.name = setValue(data.manager, 'account_manager_name');
			state.form.accountManager.email = setValue(data.manager, 'account_manager_email');
			state.form.accountManager.fax = format.phone(setValue(data.manager, 'account_manager_fax'))
			state.form.accountManager.phone = format.phone(setValue(data.manager, 'account_manager_phone'))
			//메모
			state.form.memo = setValue(data.info, 'memo');
			//일반현황정보
			state.form.fact.count = setValue(data.fact, 'fact_count');
			state.form.fact.name = setValue(data.fact, 'fact_name');
			state.form.fact.product = setValue(data.fact, 'fact_product');
		}	
	},
	resetForm: (state) => {
		let keys = Object.keys(state.form);
		let result = {}
		keys.forEach(key => {
			if(Array.isArray(state.form[key])) {
				result[key] = []
			} else if(typeof state.form[key] == 'object') {
				result[key] = {id: '', name: ''}
			} else {
				result[key] = ''
			}
		})
		state.form = {...result}
	},
	setClient: (state, data) => {
		state.searchClient = [...data]
	},
	setUpperClient: (state, data) => {
		state.searchUpperClient = [...data]
	},
	setChildClient: (state, data) => {
		state.childClient = [...data]
	},
	setManagers: (state, data) => {
		state.managers = [...data.list]
		state.managers = state.managers.map(el => {
			el.text = el.name;
			el.value = el.id;
			return el;
		})
	},
	setAccountManagers: (state, data) => {
		state.accountManagers = [...data.list]
	}
}

const actions = {
	loadClient: async ({state, commit}, params) => {
		//검색 옵션 만들기
		const res = await api.client.getList(params)
		commit('setClient', res.table.list)
	},
	loadUpperClient: async ({state, commit}, params) => {
		//검색 옵션 만들기
		const res = await api.client.getList(params)
		commit('setUpper', res.table.list)
		commit('setUpperClient', res.table.list)
	},
	loadChildClient: async ({state, commit}, params) => {
		//검색 옵션 만들기
		const res = await api.client.getList(params)
		commit('setChildClient', res.table.list)
	},
	loadClientInfo: async ({state, commit}, id) => {
		const res = await api.client.getInfo(id)
		commit('setInfo', res)
		commit('setForm', res)
		//sample - 상위 고객 테이블 형태
		commit('setUpper', res.parentClient)
		return res;
	},
	//고객사 - 담당자 목록
	loadManagerInfo: async({state, commit}, params) => {
		const {id, type} = params;
		const res = await api.client.getManager(id, type);
		if(type === 'product') {
			commit('setManagers', res)
		} else {
			commit('setAccountManagers', res)
		}
	},
	loadHistory: async ({state, commit}, id) => {
		const res = await api.project.getHistory(id, {})
		commit('setHistory', res)
	},
	add: async ({state, commit}, body) => {
		const res = await api.client.add(body)
		commit('resetForm')
		return res;
	},
	edit: async ({state, commit}, {id, body}) => {
		const res = await api.client.edit(id, body)
		commit('resetForm')
		return res;
	},
	addManager: async ({commit, dispatch}, payload) => {
		const {id,type,body} = payload
		const res = await api.client.addManager(id, type, body)
		dispatch('loadClientInfo', id)
		dispatch('loadManagerInfo', payload)
		return res;
	},
	editManager: async ({commit, dispatch}, payload) => {
		const {id,type,body} = payload
		const res = await api.client.editManager(id, body)
		dispatch('loadClientInfo', id)
		dispatch('loadManagerInfo', payload)
		return res;
	},
	deleteManager: async ({commit, dispatch}, payload) => {
		const {id, type, deleteId} = payload
		const res = await api.client.deleteManager(deleteId)
		dispatch('loadClientInfo', id)
		dispatch('loadManagerInfo', payload)
		return res;
	},
}

export default { namespaced: true, state, getters, mutations, actions }

