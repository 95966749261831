import VueCookies from "vue-cookies";
class User {
	constructor(instance) {
		this.axios = instance;
	}
	async initToken() {
		const token = JSON.parse(VueCookies.get('token'))
		if(token) {
			this.axios.defaults.headers.common["Authorization"] = token;
		}
	}
	//로그인
	async login(params) {
		if(params.keepLogin) {
			VueCookies.set('signin', JSON.stringify(params));
		}
		const response = await this.axios.post(`/user/signin`, params);
		if(response.data.token) {
			VueCookies.set('token', JSON.stringify(response.data.token));
			this.axios.defaults.headers.common["Authorization"] = response.data.token;
		}
		return response;
	}
	//로그인 상태 유지 시 자동 로그인
	async autoLogin() {
		const signin = VueCookies.get('signin');
		const result = JSON.parse(JSON.stringify(signin))
		if(result?.id) {
			return result
		} else {
			return false
		}
	}
	//비밀번호 변경하기
	async resetPw() {
		//
	}
	//유저 프로필
	async getProfile() {
		const response = await this.axios.get(`/user/profile`);
		return response.data;
	}

	async getUserNameList(field, value) {
		const response = await this.axios.get(`/user/simple/name/list`, {
			params : {
				field, value
			}
		});

		return response.data.list;
	}
	//유저 사인 정보 가져오기
	async getUserSignImg(id) {
		const response = await this.axios.get(`/user/${id}/signature`, {
			responseType: 'blob'
		});
		return response;
	}
	//시험성적서 담당자 정보 가져오기 - sampleCollector, master, researcher
	async getTestUserInfo(id, key) {
		const response = await this.axios.get(`/project/${id}/user/${key}`);
		return response.data;
	}
	//시험자 정보 가져오기
	async getResearcherInfo(type) {
		const response = await this.axios.get(`/user/${type}/researcher`);
		return response.data;
	}
	//시험 책임자 정보 가져오기
	async getMasterInfo(type) {
		const response = await this.axios.get(`/user/${type}/master`);
		return response.data;
	}
}

export default User