const tableHeaders = {
	//고객사 페이지 - 고객
	clientTableHeader: [
		{ text: '고객사 ID', headerKey: 'id' },
		{ text: '상위 고객사', headerKey: 'parent.name', type: 'link' },
		{ text: '이름', headerKey: 'name' },
		{ text: '사업자번호', headerKey: 'bizNum' },
		{ text: '연락처', headerKey: 'contact.phone', type: 'phone' },
		{ text: '담당자', headerKey: 'manager.name', type: 'user' },
		{ text: '등록일', headerKey: 'createDatetime', type: 'date' },
		{ text: '최근 진행 업무', headerKey: 'project.code', type: 'id' },
		{ text: '최근 업무 진행일', headerKey: 'createDatetime', type: 'date' }
	],
	//고객사 - 담당자
	clientManagerHeader: [
		{ key: 'selected', label: ' ' },
		{ key: 'name', label: '이름' },
		{ key: 'contact', label: '연락처' },
		{ key: 'phone', label: '휴대폰 번호'},
		{ key: 'contact', label: '팩스 번호' },
		{ key: 'email', label: '이메일' },
	],
	//고객사 상세 - 상위고객 상세 검색
	searchClientTableHeader: [
		{ text: '고객사 ID', headerKey: 'id' },
		{ text: '상위 고객사', headerKey: 'parent.name', type: 'link' },
		{ text: '이름', headerKey: 'name' },
		{ text: '사업자번호', headerKey: 'bizNum' },
		{ text: '연락처', headerKey: 'contact.phone', type: 'phone' },
		{ text: '담당자', headerKey: 'manager.name', type: 'user' },
	],
	//대시보드 - 이슈보드
	dashboardIssueTableHeader: [
		{ text: '업무 ID', headerKey: 'id' },
		{ text: '내용', headerKey: 'content' },
		{ text: '작성자', headerKey: 'manager' },
		{ text: '작성일시', headerKey: 'createDatetime', type: 'date' },
		{ text: '상태', headerKey: 'status', type: 'select-status' },
	],
	//대시보드 - 내 업무
	dashboardMyProjectTableHeader: [
		{ text: '업무 ID', headerKey: 'id' },
		{ text: '상태', headerKey: 'status', type: 'status' },
		{ text: '고객사', headerKey: 'client', type: 'name' },
		{ text: '업무 등록일', headerKey: 'createDatetime', type: 'date' },
		{ text: '종료 예정일', headerKey: 'endDatetime', type: 'date' },
		{ text: '최근 수정일시', headerKey: 'recentlyWorkDatetime', type: 'date' },
		{ text: '마지막 수정 내용', headerKey: 'content'}
	],
	//업무 페이지 - 리스트
	workTableHeader: [
		{ text: '업무 ID', headerKey: 'code' },
		{ text: '상태', headerKey: 'status', type: 'status' },
		{ text: '고객사 이름', headerKey: 'client', type: 'name' },
		{ text: '담당자', headerKey: 'assignee' },
		{ text: '구분', headerKey: 'classification' },
		{ text: '의뢰일', headerKey: 'startDatetime', type: 'date' },
		{ text: '종료예정일', headerKey: 'endDatetime', type: 'date' },
		{ text: '담당부서', headerKey: 'department' },
		{ text: '담당 연구원', headerKey: 'assigneeResearcher' },
		{ text: '시험부서', headerKey: 'researchDepartment' },
		{ text: '분석 항목 수', headerKey: 'count' },
		{ text: '용도', headerKey: 'purpose' },
		{ text: '특이사항', headerKey: 'memo' },
		{ text: '채취 장소', headerKey: 'location' },
		{ text: '최근업무', headerKey: 'recentlyWork' },
		{ text: '최근 업무 진행일', headerKey: 'recentlyWorkDatetime', type: 'date' }, 
	],
	//업무 이력
	workHistoryTableHeader: [
		{ text: '업무 ID', headerKey: 'code' },
		{ text: '시작일', headerKey: 'startDatetime', type: 'date', isSort: true },
		{ text: '종료 예정일', headerKey: 'endDatetime', type: 'date' },
		{ text: '상태', headerKey: 'status' },
		{ text: '정산', headerKey: 'amount', type: 'num' }
	],

	//업무 상세 - 정산내역
	accountHistoryTableHeader: [
		{ text: '입금일', headerKey: 'transactDate', type: 'date' },
		{ text: '정산 종류', headerKey: 'type', type: 'type' },
		{ text: '대상 고객', headerKey: 'transact_client' },
		{ text: '은행', headerKey: 'bank' },
		{ text: '금액', headerKey: 'amount', type: 'num' },
		{ text: '비고', headerKey: 'memo' },
		{ text: '첨부파일', headerKey: 'attachment', type: 'button' },
		{ text: '삭제', headerKey: 'delete', type: 'button' },
	],
	//업무 상세 - 시험 정보 - 시료별 보기
	testInfoTableHeaders: [
		{ text: '시료번호', headerKey: 'sampleNo', type: 'id'},
		{ text: '시료이름', headerKey: 'sampleName', type: 'id'},
		{ text: '진행률', headerKey: 'testCategoryProgress', type: 'process'},
		// { text: '채취 장소', headerKey: 'sampleCollectPoint' },
		{ text: '채취 지점', headerKey: 'additional.soil_area', type: 'additional'},
		{ text: '채취 심도', headerKey: 'additional.soil_depth', type: 'additional' },
		{ text: '시험 방법', headerKey: 'testWay' },
	],
	//업무 상세 - 시험 정보 - 시험항목별 보기
	categoryTestInfoTableHeaders: [
		{ text: '시험항목', headerKey: 'testGroup.testCategoryName', type: 'row' },
		{ text: '시험일지', headerKey: 'testGroup.testCategoryTestReportUrl', type: 'button' },
		{ text: '진행률', headerKey: 'testGroup.testCategoryProgress', type: 'process'},
		{ text: '시료번호', headerKey: 'sampleNo', type: 'id'},
		{ text: '시험 방법', headerKey: 'testWay' },
		{ text: '시험 결과', headerKey: 'testResult', type: 'result' },
	],
	//업무 상세 - 시험일지 작성 - 사용기기
	useDeviceTableHeader: [
		{ text: '조작 조건', headerKey: 'condition' },
		{ text: '값', headerKey: 'value' }
	],
	//업무 상세 - 시험일지 작성 - 1차 표준물질
	firstStandardTableHeader: [
		{ text: '항목', headerKey: 'name' },
		{ text: '농도', headerKey: 'concentration' },
		{ text: '제조사', headerKey: 'maker' },
		{ text: '관리번호', headerKey: 'mngCode' },
		{ text: 'Lot#', headerKey: 'lotno' },
		{ text: '개봉일자', headerKey: 'opneDate' },
		{ text: '유효일', headerKey: 'validDate' },
		{ text: '조제일자', headerKey: 'makeDate' },
		{ text: '표준 사용량', headerKey: 'stdUseVolume' },
		{ text: '최종 부피', headerKey: 'lastVolime' },
	],
	//업무 상세 - 시험일지 작성 - 2차 표준물질
	secondStandardTableHeader: [
		{ text: '조제일자', headerKey: 'name' },
		{ text: '항목', headerKey: 'makeDate' },
		{ text: 'std1', headerKey: 'std1' },
		{ text: 'std2', headerKey: 'std2' },
		{ text: 'std3', headerKey: 'std3' },
		{ text: 'std4', headerKey: 'std4' },
	],
	//업무 상세 - 시험일지 작성 - 사용시약
	useReagentTableHeader: [
		{ text: '조제일자', headerKey: 'makeDate' },
		{ text: '시약명', headerKey: 'name' },
		{ text: '취급 시 특이사항', headerKey: 'cautionText'}
	],
	//업무 상세 - 시험일지 작성 - 작업 물질 검정 (Abs, 흡광도, 농도 등)
	testingSubstanceTableHeader: [
		{ text: '항목', headerKey: 'name' },
		{ text: 'std1', headerKey: 'std1' },
		{ text: 'std2', headerKey: 'std2' },
		{ text: 'std3', headerKey: 'std3' },
		{ text: 'std4', headerKey: 'std4' },
	],
	//업무 상세 - 시험일지 작성 - 시험분석결과
	analysisResultTableHeader: [
		{ text: '시료명', headerKey: 'sampleName' },
		{ text: '시험항목', headerKey: 'testCategory' },
		{ text: '시료 농도', headerKey: 'reagentConcentration' },
		{ text: '바탕 시료 농도', headerKey: 'backgroundConcentration' },
		{ text: '희석 배수', headerKey: 'dilutionFactor' },
		{ text: '최종 농도', headerKey: 'lastConcentration' },
		{ text: '표시 농도', headerKey: 'markedConcentration' },
	],
	//업무 상세 - 시험일지 작성 - CCV
	ccvTableHeader: [
		{ text: 'CCV 날짜', headerKey: 'date' },
		{ text: '시험항목', headerKey: 'name' },
		{ text: 'Blank (mg/L)', headerKey: 'blank' },
		{ text: 'CCV 지시값 (mg/L)', headerKey: 'indicationValue'},
		{ text: 'CCV 측정값 (mg/L)', headerKey: 'measuresedValue' },
		{ text: '회수율', headerKey: 'recoveryValue' }
	],

	//업무 상세 - 시료 등록하기 - 시험정보 
	workTestCategoryHeaders: [
		{ text: '시료번호', headerKey: 'no' },
		{ text: '시료명', headerKey: 'name'},
		// { text: '채취 지점', headerKey: 'point' },
		// { text: '토양 채취 심도', headerKey: 'soilDepth'},
		// { text: '토양 지역', headerKey: 'soilArea'},
		{ text: '시험 방법', headerKey: 'testMethod', type: 'select' },
		{ text: '추가 시험 방법', headerKey: 'secondTestMethod', type: 'select' },
	],

	workTestSoilHeaders: [
		{ text: '시료번호', headerKey: 'no' },
		{ text: '시료명', headerKey: 'name'},
		// { text: '채취 지점', headerKey: 'point' },
		{ text: '토양 채취 심도', headerKey: 'soilDepth'},
		// { text: '토양 지역', headerKey: 'soilArea'},
		{ text: '시험 방법', headerKey: 'testMethod', type: 'select' },
		{ text: '추가 시험 방법', headerKey: 'secondTestMethod', type: 'select' },
	],

	//시험 분석 리스트
	analysisTableHeader: [
		{ text: '시료 ID', headerKey: 'analysisId' },
		{ text: '업무 ID', headerKey: 'workId' },
		{ text: '고객사', headerKey: 'client' },
		{ text: '분석항목', headerKey: 'classification' },
		{ text: '특이사항', headerKey: 'memo' },
		{ text: '종료 예정일', headerKey: 'endDatetime'},
		{ text: '분석완료일시', headerKey: 'anaylsisDatetime' },
		{ text: '기술책임자 승인', headerKey: 'approvalStatus', type: 'approvalStatus' },
		{ text: '상태', headerKey: 'status', type: 'changeStatus'}
	],
	//멤버 리스트
	memberTableHeader: [
		{ text: 'id', headerKey: 'id', type: 'checkbox'},
		{ text: '이름', headerKey: 'name' },
		{ text: '이메일', headerKey: 'email' },
		{ text: '연락처', headerKey: 'phone', type: 'phone' },
		{ text: '부서', headerKey: 'department' },
		{ text: '역할', headerKey: 'position' },
		{ text: '마지막 접속일시', headerKey: 'accessDatetime', type: 'date' },
		{ text: '관리', headerKey: 'edit', type: 'button'}
	],
	//시험 결과 확인 샘플
	confirmResultTableHeader: [
		{ text: '항목', headerKey: 'category', rowspan: 2 },
		{ text: '단위', headerKey: 'unit', rowspan: 2 },
		{ 
			text: '측정분석방법', 
			headerKey: 'methods', 
			sub: [
				{ text: '분석방법', headerKey: 'method' },
				{ text: '측정기기', headerKey: 'device' }
			],
		},
		{ 
			text: '검체명', 
			headerKey: 'name',
			sub: [
				{ text: 'item1', headerKey: 'item1', rowspan: 2 },
				{ text: 'item2', headerKey: 'item2', rowspan: 2 },
				{ text: 'item3', headerKey: 'item3', rowspan: 2 },
				{ text: 'item4', headerKey: 'item4', rowspan: 2 }
			], 
		} 
	],

	//업무상세 - 산출문서 - 시험 의뢰서
	testRequestTableHeader: [
		{ text: 'No', headerKey: 'id' },
		{ text: '파일명', headerKey: 'fileName' },
		{ text: '발행일', headerKey: 'createDatetime', type: 'date' },
		{ text: '발행자', headerKey: 'userId' },
		{ text: '다운로드', headerKey: 'download', type: 'button' },
		{ text: '삭제', headerKey: 'delete', type: 'button' },
	],

	//업무상세 - 산출문서 - 시험일지
	testResultTableHeader: [
		{ text: 'No', headerKey: 'projectId' },
		{ text: '파일명', headerKey: 'fileName' },
		{ text: '시험 항목', headerKey: 'testName' },
		{ text: '발행일', headerKey: 'createDatetime', type: 'date' },
		{ text: '다운로드', headerKey: 'download', type: 'button' },
		{ text: '삭제', headerKey: 'delete', type: 'button' },
	],

	//업무상세 - 산출문서 - 시험성적서 
	testReportTableHeader: [
		{ text: 'No', headerKey: 'no' },
		{ text: '파일명', headerKey: 'name' },
		{ text: '설명', headerKey: 'description' },
		{ text: '발행일', headerKey: 'datetime' },
		{ text: '시험 담당자', headerKey: 'maker' },
		{ text: '다운로드', headerKey: 'download' },
		{ text: '삭제', headerKey: 'delete', type: 'button' },
	],
	documentTableHeader: [
		{ text: 'No', headerKey: 'id' },
		{ text: '파일명', headerKey: 'filename' },
		{ text: '발행일', headerKey: 'createDatetime', type: 'date' },
		{ text: '다운로드', headerKey: 'download', type: 'button' },
		{ text: '삭제', headerKey: 'delete', type: 'button' },
	]
}

export default tableHeaders