<template>
	<div class="table-layout">
		<div class="title d-flex">
			<h5 class="table-title" v-if="title">
				{{title}}
				 <date-picker 
				 	v-if="isRange"
				 	v-model="range" 
					type="date" 
					range
					@input="$emit('range', range)"
				/>
			</h5>
			<div class="detail-btns">
				<slot></slot>
			</div>
		</div>
		<div class="sub-com">
			<slot name="sub-com"/>
		</div>
		<b-table 
			sticky-header
			show-empty
			:fields="headers"  
			:items="list"
			:busy="isLoading"
			@row-clicked="onRow"
			:selectable="isSelected"
			:tbody-tr-class="trClass"
			ref="table"
			@row-selected="onRowSelected"
		>
			<!--sorting-->
			<template #head()="data">
				<div @click="$emit('sort', data.field.key, data.field.sort === 'ASC' ? 'DESC' : 'ASC')">
					<span>{{data.label}}</span>
					<img 
						v-if="data.field.isSort" 
						class="sort-img" 
						:class="data.field.sort ? 'active' : 'unactive'"
						:src="require(`@/assets/img/sort-arrow-${data.field.sort === 'ASC' ? 'up' : 'down'}.svg`)"
					>
				</div>
			</template>
			<template #head(selected)>
				<img 
					:class="selectedClass"
					:src="require(`@/assets/img/form-checkbox-check-${allChecked ? 'on' : 'off'}.png`)"
					@click="allChecked = !allChecked"
				>
			</template>
			<!--로딩-->
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
			<!--데이터가 없을 때-->
			<template #empty>
				<div v-html="noContent" />
			</template>
			<template #cell(selected)="{rowSelected}">
				<img :class="rowSelected ? 'on' : 'off'" :src="require(`@/assets/img/form-checkbox-check-${rowSelected ? 'on' : 'off'}.png`)">
			</template>
			<template #cell()="row">
				<button v-if="row.field.link" class="detail-btn" v-text="row.value" @click="onRow(row.item, row.index, row.value)"/>
				<div v-else-if="row.value.img" :class="row.value.value">
					<img :src="row.value.img">
					<p>{{row.value.text}}</p>
				</div>
				<p 
					v-else-if="row.field.key === 'memo' || row.field.key === 'content'" 
					v-b-tooltip.hover.html="toHTML(row.value)"
					v-html="toHTML(row.value)"
				> 
				</p>
				<p v-else>
					{{row.value}}
				</p>
			</template>
			<!--상태 / badge 형태-->
			<template #cell(status)="data">
				<badges	
					v-if="data.field.type === 'select-status'"
					:text="data.item.status.text"
					:color="data.item.status.color"
					:textColor="data.item.status.textColor"
					:options="status.issue"
					isBadge
					@select="option => $emit('status', option, data.item.id)"
					dropright
				/>
				<b-badge v-else :style="`background-color: var(--ehti-${data.value.color})`">{{data.value.text}}</b-badge>
			</template>
			<!--페이지 이동 / 고객사 이동-->
			<template #cell(client)="row">
				<button class="detail-btn" v-text="row.value" @click="onRow(row.item, row.index, row.value)"/>
			</template>
			<!--다운로드 버튼-->
			<template #cell(download)="row">
				<!-- <e-btn
					text="다운로드"
					:icon="require('@/assets/img/button-download.png')"
					@click="$emit('download', row.item)"
				/> -->
				<a 
					:href="`https://etps-service.ehti.kr${row.item.url}`" 
					download
					target="_blank"
					class="v-btn v-btn--has-bg theme--light v-size--default"
				>
					<span style="letter-spacing: 0rem;">
						<img :src="require('@/assets/img/button-download.png')"/>
						다운로드
					</span>
				</a>
			</template>
			<!--수정버튼-->
			<template #cell(edit)="row">
				<e-btn
					text="수정"
					:icon="require('@/assets/img/button-edit.png')"
					@click="$emit('edit', row.item)"
				/>
			</template>
			<!--삭제 버튼-->
			<template #cell(delete)="row">
				<e-btn
					text="삭제"
					:icon="require('@/assets/img/button-delete.png')"
					@click="$emit('delete', row.item)"
				/>
			</template>
			<!--진행률-->
			<template #cell(progress)="row">
				<small-doughnut :ratio="row.value * 10"/>
			</template>
			<template #cell(isSample)="row">
				{{row}}
			</template>
		</b-table>
		<div class="table-pagination" v-if="isPagination">
			<b-pagination
				v-model="current"
				:per-page="perPage"
				:total-rows="count"
				hide-ellipsis
				@change="onPage"
			>
				<template #first-text>
					<img style="transform: rotate(180deg);" src="@/assets/img/arrow-next-all.png">
				</template>
				<template #prev-text>
					<img style="transform: rotate(180deg);" src="@/assets/img/arrow-next.png">
				</template>
				<template #next-text>
					<img src="@/assets/img/arrow-next.png">
				</template>
				<template #last-text>
					<img src="@/assets/img/arrow-next-all.png">
				</template>
			</b-pagination>
			<b-form-select 
				v-model="perPage"
				:options="pageOptions"
			></b-form-select>
		</div>
	</div>
</template>

<script>
import RangePicker from 'vue2-datepicker';
import Badges from '../button/badges.vue';
import smallDoughnut from '../chart/smallDoughnut.vue';
import status from '@/utils/status'

export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		headers: {
			type: Array,
			default: () => []
		},
		list: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isRange: {
			type: Boolean,
			default: false
		},
		isPagination: {
			type: Boolean,
			default: false
		},
		count: {
			type: Number,
			default: 1
		},
		page: {
			type: String,
			default: ''
		},
		noContent: {
			type: String,
			default: `<p class="no-content">등록된 항목이 없습니다.</p>`
		},
		isSelected: {
			type: Boolean,
			default: false
		},
		selected: {
			type: Array,
			default: () => []
		},
		params: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		RangePicker,
		smallDoughnut,
		Badges
	},
	created() {
		if(this.params?.page) {
			this.current = this.params.page
		}
		if(this.params?.count) {
			this.perPage = this.params.count
		}
		if(this.isRange) {
			this.range[0] = this.params?.filter?.startDatetime;
			this.range[1] = this.params?.filter?.endDatetime;
		}
	},
	mounted() {
		if(this.page === 'detail' || this.page === 'dashboard') {
			this.perPage = 5;
			this.pageOptions = [
				{ text: '5개', value: 5 },
				{ text: '10개', value: 10 },
				{ text: '20개', value: 20 },
				{ text: '50개', value: 50 },
			]
		}
	},
	computed: {
		total() {
			return this.count / this.perPage
		},
		selectedClass() {
			if(this.selected.length > 0) {
				if(this.selected.length < this.list.length) {
					return 'indeterminate'
				} else {
					return 'on'
				}
			} else {
				return 'off'
			}
		}
	},
	watch: {
		allChecked(val) {
			if(val) {
				this.$refs.table.selectAllRows()
			} else {
				this.$refs.table.clearSelected()
			}
		},
		params: {
			deep: true,
			handler(val) {
				this.current = val.page;
				this.perPage = val.count;
			}
		}
	},
	data() {
		return {
			current: 1,
			perPage: 30,
			pageOptions: [
				{ text: '30개', value: 30 },
				{ text: '50개', value: 50 },
				{ text: '70개', value: 70 },
				{ text: '100개', value: 100 },
			],
			range: [new Date(), new Date()],
			allChecked: false,
			status: status
		}
	},
	methods: {
		onRow(item, index, e) {
			this.$emit('onRow', item, index, e)
		},
		onPage(current) {
			this.$refs['table'].$el.scrollTop = 0;
			this.$emit('onPage', current, this.perPage)
		},
		trClass(item) {
			if(item?.isNew) {
				return 'is-new'
			}
		},
		onRowSelected(items) {
			this.$emit('selected', items)
		},
		toHTML(value) {
			return `${value.replace(new RegExp('\r?\n','g'), '<br>')}`;
		}
	}
}
</script>