<template>
	<v-btn 
		depressed 
		:small="size == 'sm'"
		:large="size == 'lg'"
		:text="styleType == 'text'"
		:color="color"
		:disabled="disabled"
		@click="$emit('click')"
	>
		<img 
			v-if="icon" 
			:src="icon"
		>
		{{text}}
	</v-btn>
</template>

<script>
export default {
	name: 'EtpsBtn',
	props: {
		text: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: ''
		},
		styleType: {
			type: String,
			default: ''
		},
		color: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {

		}
	}
}
</script>