const options = {
	//담당부서
	deptSample : [
		{ text: '토양팀', value: 'SOIL' },
		{ text: '수질팀(CS팀)', value: 'WATER' },
		{ text: '대기팀', value: 'AIR' },
	],
	//시험부서
	anaDeptSample : [
		{ text: '토양분석팀', value: 'SOIL' },
		{ text: '수질분석팀', value: 'WATER' },
		{ text: '대기분석팀', value: 'AIR' },
		{ text: '폐기물분석팀', value: 'DISPOSAL' },
	],
	//담당자
	managerSample : [
		{ text: '정동주', value: '정동주' },
		{ text: '김은혜', value: '김은혜' },
	],
	//시료 수령 방법
	deliveryWaySample : [
		{ text: '방문 채취', value: '방문 채취' },
		{ text: '지참 시료', value: '지참 시료' },
	],
	//업무구분
	typeSample : [
		{ text: '토양(개발테스트)', value: 'EA' },
		{ text: '수질(개발테스트)', value: 'EL' },
		{ text: '대기(개발테스트)', value: 'EE' },
		{ text: '폐기물(개발테스트)', value: 'EK' },
	],
	//시료용기
	sampleContainerSample : [
		{ text: 'PET', value: 'PET' },
		{ text: 'VIAL', value: 'VIAL' },
		{ text: '광구병', value: '광구병' },
		{ text: '마대', value: '마대' },
	],
	//시험방법
	testMethodSample : [
		{ text: '토양오염공정시험기준', value: '토양오염공정시험기준' },
		{ text: '토양이화학분석법', value: '토양이화학분석법' },
	],
	//unit
	unitOption: [
		{ text: 'mg/L', value: 'mg/L' },
		{ text: 'mL', value: 'mL' },
		{ text: '%', value: '%' },
		{ text: 'g', value: 'g' },
		{ text: 'mg/kg', value: 'mg/kg' },
		{ text: 'ng', value: 'ng' },
		{ text: 'ug/L', value: 'ug/L' },
		{ text: 'Cps', value: 'Cps' },
		{ text: 'Area', value: 'Area' },
	],
	//옵션 샘플
	optionsSample : [
		{ text: '샘플 옵션 1번', value: 'option1' },
		{ text: '샘플 옵션 2번', value: 'option2' },
		{ text: '샘플 옵션 3번', value: 'option3' },
		{ text: '샘플 옵션 4번', value: 'option4' },
	],
	docType : [
		{text : "ECO-A - 폐석탄", value : "ECO-A"},
		{text : "ECO-B - 폐금속", value : "ECO-B"},
		{text : "ECO-C - 오염우려", value : "ECO-C"},
		{text : "EA - 법정", value : "EA"},
		{text : "EB - 정밀", value : "EB"},
		{text : "EC - 검증", value : "EC"},
		{text : "ED - 자체 ", value : "ED"},
		{text : "EE - 일반", value : "EE"},
		{text : "EF - 평가", value : "EF"},
		{text : "EG - 수질측정", value : "EG"},
		{text : "EI - 대기측정", value : "EI"},
		{text : "EK - 환경평가", value : "EK"},
		{text : "EL - 수질", value : "EL"}
	],
	useFor : [
		{text : "제출용(감리단)", value : "제출용(감리단)"},
		{text : "제출용", value : "제출용"},
		{text : "정밀조사", value : "정밀조사"},
		{text : "품질관리용", value : "품질관리용"},
		{text : "토양조사용", value : "토양조사용"},
		{text : "참고용", value : "참고용"},
		{text : "제출용(수도권매립지관리공사)", value : "제출용(수도권매립지관리공사)"},
		{text : "자체조사", value : "자체조사"},
		{text : "개황조사", value : "개황조사"},
		{text : "참고용 (정화검증 사전검사)", value : "참고용 (정화검증 사전검사)"},
		{text : "정화검증", value : "정화검증"},
		{text : "과정검증", value : "과정검증"},
		{text : "실태조사", value : "실태조사"},
		{text : "자체현황조사", value : "자체현황조사"},
		{text : "참고용(환경영향평가 기초자료)", value : "참고용(환경영향평가 기초자료)"},
		{text : "정화 모니터링", value : "정화 모니터링"},
		{text : "사후환경평가", value : "사후환경평가"},
		{text : "토양환경평가", value : "토양환경평가"},
		{text : "어린이용품의 환경진단", value : "어린이용품의 환경진단"},
		{text : "재검증", value : "재검증"},
		{text : "자체정밀조사", value : "자체정밀조사"},
		{text : "보도용", value : "보도용"},
		{text : "실시설계", value : "실시설계"},
		{text : "연구실험용", value : "연구실험용"},
		{text : "환경영향평가용", value : "환경영향평가용"},
		{text : "토양개황 및 정밀조사", value : "토양개황 및 정밀조사"},
		{text : "자체조사(준정밀조사)", value : "자체조사(준정밀조사)"},
		{text : "참고", value : "참고"},
		{text : "정화검증(굴착공정)", value : "정화검증(굴착공정)"},
		{text : "토양개황조사", value : "토양개황조사"},
		{text : "정화검증(1차 과정검증)", value : "정화검증(1차 과정검증)"},
		{text : "수처리장 모니터링", value : "수처리장 모니터링"},
		{text : "추가정밀조사", value : "추가정밀조사"},
		{text : "자체토양정밀조사", value : "자체토양정밀조사"},
		{text : "완료검증", value : "완료검증"},
		{text : "폐기물분석기관지정 신청용", value : "폐기물분석기관지정 신청용"},
		{text : "추가 정밀조사", value : "추가 정밀조사"},
		{text : "과정검증(굴착공정)", value : "과정검증(굴착공정)"},
		{text : "과정검증(굴착검증)", value : "과정검증(굴착검증)"},
		{text : "성적서 보관용", value : "성적서 보관용"},
		{text : "환경관련 보조자료", value : "환경관련 보조자료"},
		{text : "완료검증(굴착공정)", value : "완료검증(굴착공정)"},
		{text : "저수조 수질검사", value : "저수조 수질검사"},
		{text : "토양환경평가(개황조사)", value : "토양환경평가(개황조사)"},
		{text : "내부참고용", value : "내부참고용"},
		{text : "환경영향평가", value : "환경영향평가"},
		{text : "법정검사", value : "법정검사"},
		{text : "모래안전검사", value : "모래안전검사"},
		{text : "기타(어린지 놀이시설 바닥재 관리)", value : "기타(어린지 놀이시설 바닥재 관리)"},
		{text : "굴착검증", value : "굴착검증"},
		{text : "사후환경영향조사", value : "사후환경영향조사"},
		{text : "오염범위확인조사", value : "오염범위확인조사"},
		{text : "제출용(감정평가용)", value : "제출용(감정평가용)"},
		{text : "감정평가", value : "감정평가"},
		{text : "내부정도관리", value : "내부정도관리"},
		{text : "오염조사", value : "오염조사"},
		{text : "토양환경평가(정밀조사)", value : "토양환경평가(정밀조사)"},
		{text : "픔질관리용", value : "픔질관리용"},
		{text : "제츨용", value : "제츨용"},
		{text : "환경영향평가용(개황조사)", value : "환경영향평가용(개황조사)"},
		{text : "품질시험용", value : "품질시험용"},
		{text : "정기검사", value : "정기검사"},
		{text : "교차검증", value : "교차검증"},
		{text : "토양환경평가", value : "토양환경평가"},
		{text : "(정밀조사)", value : "(정밀조사)"},
		{text : "오염원인자규명", value : "오염원인자규명"},
		{text : "자가측정용", value : "자가측정용"},
		{text : "전략환경영향평가", value : "전략환경영향평가"},
		{text : "정밀(상세)조사", value : "정밀(상세)조사"},
		{text : "자체확인용", value : "자체확인용"},
		{text : "참고용/품질관리용", value : "참고용/품질관리용"},
		{text : "개황 및 정밀조사", value : "개황 및 정밀조사"},
		{text : "법정검사(정기검사)", value : "법정검사(정기검사)"},
		{text : "법정검사용", value : "법정검사용"},
		{text : "토양정밀(개황)조사", value : "토양정밀(개황)조사"},
		{text : "수질조사", value : "수질조사"},
		{text : "토양정밀(개황 및 상세)조사", value : "토양정밀(개황 및 상세)조사"},
		{text : "기술진단", value : "기술진단"},
		{text : "토양정밀(상세)조사", value : "토양정밀(상세)조사"},
		{text : "사후환경영향조사용", value : "사후환경영향조사용"},
		{text : "정밀(개황)조사", value : "정밀(개황)조사"},
		{text : "소규모환경영향평가", value : "소규모환경영향평가"},
		{text : "유통기한연장신청용", value : "유통기한연장신청용"},
		{text : "법원감정용", value : "법원감정용"},
		{text : "오염현황조사", value : "오염현황조사"},
		{text : "현장검증", value : "현장검증"},
		{text : "토양오염추가조사", value : "토양오염추가조사"},
		{text : "보완조사", value : "보완조사"},
		{text : "사후환경영향평가", value : "사후환경영향평가"},
		{text : "모니터링", value : "모니터링"},
		{text : "사후환경영향평가용", value : "사후환경영향평가용"},
		{text : "소규모 환경영향평가용", value : "소규모 환경영향평가용"},
		{text : "토양오염 현황조사", value : "토양오염 현황조사"},
		{text : "기타(실험용)", value : "기타(실험용)"},
		{text : "토양환경평가", value : "토양환경평가"},
		{text : "감정평가용", value : "감정평가용"},
		{text : "사후모니터링", value : "사후모니터링"},
		{text : "전략환경영향평가용", value : "전략환경영향평가용"},
		{text : "토양정밀조사", value : "토양정밀조사"},
		{text : "사후환경영향조사(철거 전)", value : "사후환경영향조사(철거 전)"},
		{text : "용도 텍스트", value : "용도 텍스트"},
		{text : "토지 검사용 ", value : "토지 검사용 "},
		{text : "토양(개발테스트)", value : "토양(개발테스트)"}
	],
	cvvType: [
		{ text: 'ABS', value: 'ABS' },
		{ text: '농도', value: '농도' },
		{ text: 'CPS', value: 'CPS' },
		{ text: 'area', value: 'area' },
	]
}

export default options