import api from '@/api'

const state = {
	loginForm: {
		loginId: '',
		password: '',
		keepLogin: true,
	},
	profile: {
		id: '',
		login_id: "",
		password: "",
		name: '',
		email: '',
		permission: "",
		create_datetime: "",
		update_datetime: "",
		delete_datetime: null
	},
	pwForm: {
		email: '',
		password: '',
		newPassword: '',
	},
	master: {}
}

const getters = {
	loginForm: state => state.loginForm,
	pwForm: state => state.pwForm,
	profile: state => state.profile,
	master: state => state.master
}

const mutations = {
	setLoginForm: (state, data) => {
		state.loginForm = {...data}
	},
	setPwForm: (state, data) => {
		state.pwForm = {...data}
	},
	setProfile: (state, data) => {
		state.profile = {...data}
	},
	setMasterInfo: (state, data) => {
		state.master = {...data}
	}
}

const actions = {
	login: async ({commit, state}, params) => {
		const res = await api.user.login(params);
		return res;
	},
	autoLogin: async({commit, state}) => {
		const res = await api.user.autoLogin()
		return res;
	},
	resetPw: async ({commit, state}) => {
		const res = await api.user.resetPw(state.pwForm)
		return res;
	},
	getProfile: async({commit, state}) => {
		const res = await api.user.getProfile() 
		commit('setProfile', res)
	},
	getSignImg: async({commit, state}, id) => {
		const res = await api.user.getUserSignImg(id);

		function blobToBase64(blob) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.onerror = reject;
				reader.readAsDataURL(blob);
			});
		}

		const base64String = await blobToBase64(res.data);
        return base64String;
	},
	getMasterInfo: async({commit, state}, type) => {
		const res = await api.user.getMasterInfo(type)
		commit('setMasterInfo', res)
	}
}

export default { namespaced: true, state, getters, mutations, actions }