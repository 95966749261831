class Client {
	constructor(instance) {
		this.axios = instance;
	}
	async getList(params) {
		try {
			const response = await this.axios.get(`/client/list`, {params})
	
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	async getInfo(id) {
		try {
			const response = await this.axios.get(`/client/${id}?renderFormat=true`)
	
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	async add(info) {
		try {
			const response = await this.axios.put(`/client`, info)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	async edit(id, body) {
		try {
			const response = await this.axios.patch(`/client/${id}`, body)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	//고객사 담당자 목록
	async getManager(id, type) {
		try {
			const response = await this.axios.get(`/client/manager/${id}/${type}`)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	//고객사 담당자 추가
	async addManager(id, type, body) {
		try {
			const response = await this.axios.post(`/client/manager/${id}/${type}`, body)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	//고객사 담당자 수정
	async editManager(id, body) {
		try {
			const response = await this.axios.put(`/client/manager/${id}`, body)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	//고객사 담당자 삭제
	async deleteManager(id) {
		try {
			const response = await this.axios.delete(`/client/manager/${id}`)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
}

export default Client